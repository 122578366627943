const commonColors = {
    transition: '250ms',
    transitionFast: '150ms',
    white: 'rgba(255, 255, 255, 1)',
    white2: 'rgba(251, 251, 251, 1)',
    white3: 'rgba(232, 234, 234, 1)',
    black: 'rgba(0, 0, 0, 1)',
    black47: 'rgba(0, 0, 0, 0.47)',
    black83: 'rgba(0, 0, 0, 0.83)'
};

const themeColorsArray = [
    {
        title: 'Mayro Light',
        colors: {
            ...commonColors,
            primary: 'rgba(211, 54, 57, 1)',
            primarySecond: 'rgba(175, 175, 175, 1)',
            primaryThird: 'rgba(238, 238, 238, 1)',
            primaryForth: 'rgba(227, 227, 227, 1)',
            primaryFifth: 'rgba(0, 119, 164, 1)'
        }
    }]

const colors = {
    primary: '--primary',
    primarySecond: '--primary-second',
    primaryThird: '--primary-third',
    primaryForth: '--primary-forth',
    primaryFifth: '--primary-fifth',
    white: '--color-white',
    white2: '--color-white-2',
    white3: '--color-white-3',
    black: '--color-black',
    black47: '--color-black47',
    black83: '--color-black83'
}

export { colors, themeColorsArray }