import styled from "styled-components";

export const SubscribeFormBox = styled.form`
    display: flex;
    width: 100%;
  .subscribe-input{
    width: 14.75rem;
  }
  .subscribe-btn{
    width: 8.312rem;
    flex-shrink: 0;
  }
`