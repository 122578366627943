import React from "react";
import styled from "styled-components";
import parallaxImage from '../../../assets/img/parallax/parallax.jpg'

const StyledParallax = styled.div`
  position: relative;
  width: 100%;
  
  &::before {
    content: '';
    display: block;
    padding-top: 37.5%;
  }
`

const StyledParallaxBack = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${parallaxImage});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media(max-width: 768px){
    background-attachment: unset;
  }
`


const Parallax = () => {
    return (
        <StyledParallax>
            <div className='img-container'>
                <StyledParallaxBack />
            </div>
        </StyledParallax>
    )
};

export default Parallax;