import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import * as styled from './style';

export const NavDropdownItem = ({title, to}) => {
    return (
        <styled.NavDropdownItem>
            <styled.NavDropdownLink to={to}>
                {title}
            </styled.NavDropdownLink>
        </styled.NavDropdownItem>
    )
}

export const NavItem = ({title, to, dropdown, children}) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const location = useLocation()
    useEffect(()=>{
        setOpenDropdown(false)
    },[location])
    const dropdownClickHandler = () => {
        setOpenDropdown(!openDropdown)
    }
    return (
        <styled.NavItem>
            {!dropdown ? (
                <styled.NavItemLink to={to}>
                    {title}
                </styled.NavItemLink>
            ) : (
                <>
                    <styled.NavItemButton onClick={dropdownClickHandler}>
                        <styled.NavItemText active={openDropdown}>
                            {title}
                        </styled.NavItemText>
                        <styled.NavItemIcon active={openDropdown}>
                        <span className='img-container'>
                            <svg className="icon">
                              <use xlinkHref="#arrow-down"/>
                            </svg>
                        </span>
                        </styled.NavItemIcon>
                    </styled.NavItemButton>
                    {openDropdown && (
                        <styled.NavDropdown>
                            <styled.NavDropdownList>
                                {children}
                            </styled.NavDropdownList>
                        </styled.NavDropdown>
                    )}
                </>
            )}

        </styled.NavItem>
    )
}

const Nav = ({children, linksColor}) => {

    return (
        <styled.Nav linkColor={linksColor}>
            <styled.NavList>
                {children}
            </styled.NavList>
        </styled.Nav>
    )
}

export default Nav;