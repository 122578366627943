import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { colors } from "../../../utils/theme";

export const Comics = styled.section`
  padding-top: 1.25rem;
  margin-bottom: 9.5rem;
  scroll-behavior: smooth;
  @media(max-width: 768px){
    margin-bottom: .8125rem;
  }
`
export const ComicsSwiper = styled(Swiper)`
padding-bottom: 2.625rem;

.swiper-slide {
  display: flex;
  align-items: center;
}

.h-comics-swiper-nav {
  margin-right: 0;
  cursor: pointer;

  img {
    margin-top: 1.5rem;
    transition: transform 0.4s ease, width 0.4s ease, height 0.4s ease;

    &:hover {
      transform: scale(1.1); /* Scale up the image on hover */
    }
  }
}

@media(max-width: 768px) {
  padding-bottom: 0;

  .h-comics-swiper-nav img {
    &:hover {
      transform: none; /* Disable scaling on hover for mobile */
    }
  }
}
`;
export const ComicsSlideWrapper = styled(SwiperSlide)`
    display: flex;
    margin-top: 2.2rem;
    @media(max-width: 768px){
      flex-direction: column;
      width: 100%;
      margin-top: .5rem;
    }
`
export const ComicsSwiperLeft = styled.div`
  width: 40.017%;
  margin-right: auto;
  @media(max-width: 1280px){
    width: 50%;
  }
  
  @media(max-width: 768px){
    width: 100%;
    margin-bottom: 1.375rem;
  }
`

export const ComicsTitleContainer = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 2.5rem;
`

export const ComicsTitle = styled.h2`
  position: relative;

  color: var(${colors.primary});

  font-family: 'Teko', sans-serif;
  font-size: 3.75rem;
  line-height: 5.375rem;
  font-weight: 700;
  text-transform: capitalize;
  z-index: 2;
  margin-top: 0;
  @media(max-width: 1280px){
    font-size: 2.5rem;
    line-height: 3.5625rem;
  }
  @media(max-width: 345px){
    font-size: 2rem;
    line-height: 3rem;
  }
`

export const ComicsTitleOrder = styled.div`
  position: absolute;
  top: 65%;
  left: 0.4rem;
  transform: translateY(-50%);

  color: var(${colors.primaryForth});

  font-family: 'Teko', sans-serif;
  font-size: 12.5rem;
  line-height: 17.875rem;
  font-weight: 500;
  z-index: 1;
  @media(max-width: 1280px){
    font-size: 6.5rem;
    line-height: 8.9375rem;
  }
  
`

export const ComicsTextContainer = styled.div`
  position: absolute;
  top: 77%;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ComicsTextLine = styled.div`
  height: 0.25rem;
  flex-grow: 1;
  margin-top: 1rem;

  background-color: var(${colors.primary});
`

export const ComicsText = styled.span`
  position: relative;
  margin-right: 2.4rem;
  margin-left: 0.937rem;

  color: var(${colors.primary});

  font-family: 'Teko', sans-serif;
  font-size: 3.75rem;
  line-height: 5.375rem;
  font-weight: 700;
  text-transform: capitalize;
  @media(max-width: 1280px){
    font-size: 2.5rem;
    line-height: 3.5625rem;
  }
  @media(max-width: 345px){
    font-size: 2rem;
    line-height: 3rem;
  }
`

export const ComicsDesc = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 0.8rem;

  color: var(${colors.black});

  font-family: 'Open Sans', sans-serif;
  font-size: 1.0625rem;
  line-height: 1.362rem;
  @media(max-width: 768px){
    padding: 2.1875rem 0 2.06rem 0;
    font-size: .75rem;
    line-height: 1rem;
  }
`
export const ComicsSwiperRight = styled.div`
  width: 48.8%;
  @media(max-width: 768px){
    width: 100%;
  }
`

export const ComicsSwiperImg = styled.div`
  position: relative;
  width: 100%;
  
  img {
    width: 100%;
    object-fit: cover;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 94.210%;
  }
`