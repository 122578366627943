import * as styled from "./style";

const InputSimple = ({value, onChange, className})=>{
    return (
        <styled.InpSimple
            placeholder='Email'
            value={value}
            onChange={onChange}
            className={className}
        />
    )
}
export default InputSimple;