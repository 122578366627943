import React, { useState } from "react";
import { Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";
import SwiperNavigation from "../../../components/Global/SwiperNavigation";
import PrimaryBtn from "../../../components/Global/PrimaryBtn";
import * as styled from "./style";

import sliderImage1 from '../../../assets/img/cooperateSwiper/Jazzve.jpg'
import sliderImage2 from '../../../assets/img/cooperateSwiper/aznivGogher.jpg'
import jazzveLogo from '../../../assets/img/cooperateSwiper/jazzveLogo.png'
import demqLogo from '../../../assets/img/cooperateSwiper/demqLogo.png'
import { cooperationUtil } from "./cooperateUtil";
import { useNavigate } from "react-router-dom";

const Cooperate = () => {
    const [activeImg, setActiveImg] = useState('prev');
    const navigate = useNavigate();
    const handleClickNext = () => {
        setActiveImg('next')
    };
    const handleClickPrev = () => {
        setActiveImg('prev')
    };
    const handleRedirect = () => {
        navigate('/contact-us')
    };
    return (
        <styled.Cooperate>
            <styled.CooperateContainer>
                <styled.CooperateTextContainer>
                    <styled.CooperateTitle>
                        Cooperate Is
                    </styled.CooperateTitle>
                    <styled.CooperateSubTitleContainer>
                        <styled.CooperateSubTitleLine />
                        <styled.CooperateSubTitle>
                            Great
                        </styled.CooperateSubTitle>
                    </styled.CooperateSubTitleContainer>

                    <styled.CooperateDesc>
                        {activeImg === 'prev' ? cooperationUtil[0].cooperationText : cooperationUtil[1].cooperationText}
                    </styled.CooperateDesc>


                    <styled.CooperateLogoContainer>
                        <styled.CooperateLogo
                            logoWidth='4.625rem'
                            logoHeight='55.405%'
                            marginRight='1rem'
                        >
                            <div className='img-container'>
                                <img src={demqLogo} alt='demq-logo' />
                            </div>
                        </styled.CooperateLogo>
                        <styled.CooperateLogo
                            logoWidth='3.062rem'
                            logoHeight='100%'
                            marginRight='0'
                        >
                            <div className='img-container'>
                                <img src={jazzveLogo} alt='jazzve-logo' />
                            </div>
                        </styled.CooperateLogo>
                    </styled.CooperateLogoContainer>
                    <PrimaryBtn
                        onClickBtn={handleRedirect}
                    >
                        Learn More
                    </PrimaryBtn>
                </styled.CooperateTextContainer>
                <styled.CooperateSwiperContainer>
                    <styled.CooperateSwiper
                        navigation={{
                            nextEl: '.next',
                            prevEl: '.prev'
                        }}
                        modules={[Navigation]}
                        className='cooperate-swiper'
                        loop={true}
                        breakpoints={
                            {
                                768: {
                                    slidesPerView: 1.1923,
                                    spaceBetween: 38
                                },
                                376: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 16
                                },
                                100: {
                                    slidesPerView: 1.1437,
                                    spaceBetween: 16
                                }
                            }

                        }
                    >
                        <SwiperSlide>
                            <styled.CooperateSwiperImage>
                                <div className='img-container'>
                                    <img src={sliderImage1} alt='Jazzve' />
                                </div>
                            </styled.CooperateSwiperImage>
                        </SwiperSlide>
                        <SwiperSlide>
                            <styled.CooperateSwiperImage>
                                <div className='img-container'>
                                    <img src={sliderImage2} alt='Azniv Gogher' />
                                </div>
                            </styled.CooperateSwiperImage>
                        </SwiperSlide>
                        <SwiperNavigation
                            className='cooperate-swiper-nav'
                            handleClickNext={handleClickNext}
                            handleClickPrev={handleClickPrev}
                        />
                    </styled.CooperateSwiper>
                </styled.CooperateSwiperContainer>
            </styled.CooperateContainer>
        </styled.Cooperate>
    )
};

export default Cooperate;