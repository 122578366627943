import * as styled from "./style";

const PrimaryBtn = ({children, onClickBtn, className, mini ,type = 'button', ...styles})=>{
    //refactor styles props, get button sizes with class

    return (
        <styled.Btn
            type={type}
            onClick={onClickBtn}
            className={className}
            mini={mini}
            {...styles}
        >
            {children}
        </styled.Btn>
    )
}

export default PrimaryBtn;