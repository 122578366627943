import React from "react";
import SubscribeForm from "../../components/Global/SubscribeForm/index";
import * as styled from './style'

const Footer = () => {
    return (
        <styled.Footer>
            <styled.FooterOpacity />
            <div className='page-container-mini'>
                <styled.FooterContainer>
                    <styled.FooterNavContainer>
                        <styled.FooterNavElement>
                            <styled.FooterTitle>Links</styled.FooterTitle>
                            <styled.FooterNavList>
                                <FooterItem title='About us' to='/about-us' />
                                <FooterItem title='Comic Books' to='/comic-books' />
                                <FooterItem title='Short Comics' to='/short-comics' />
                                <FooterItem title='Gallery' to='/gallery' />
                                <FooterItem title='Contact us' to='/contact-us' />
                            </styled.FooterNavList>
                        </styled.FooterNavElement>
                        <styled.FooterNavElement>
                            <styled.FooterTitle>Follow us</styled.FooterTitle>
                            <styled.FooterNavList>
                                <FooterItem title='Facebook' to='https://www.facebook.com/mayro.comics' isLinkOtherPage />
                                <FooterItem title='Instagram' to='https://www.instagram.com/mayro_comics/' isLinkOtherPage />
                                <FooterItem title='Linkedin' to='https://www.linkedin.com/company/mayrocomics/' isLinkOtherPage />
                            </styled.FooterNavList>
                        </styled.FooterNavElement>
                        <styled.FooterNavElement>
                            <styled.FooterTitle>Subscribe now</styled.FooterTitle>
                            <styled.FooterNavText marginBottom='0.625rem'>
                                Get the latest updates and offers
                            </styled.FooterNavText>
                            <styled.FooterNavForm>
                                <SubscribeForm />
                            </styled.FooterNavForm>
                            <styled.FooterNavText marginBottom='0.25rem'>
                                <a href="tel:+374 41 193113"
                                    style={{ color: "unset", textDecoration: "unset" }}
                                >
                                    +374 41 193113
                                </a>
                            </styled.FooterNavText>
                            <styled.FooterNavText>
                                <a href="mailto:info@mayro.org"
                                    style={{ color: "unset", textDecoration: "unset" }}
                                >
                                    info@mayro.org
                                </a>
                            </styled.FooterNavText>
                        </styled.FooterNavElement>
                    </styled.FooterNavContainer>
                    <styled.FooterSubContainer>
                        <styled.FooterSubText>
                            Developed by  <a href="https://mayro.io/" target='_blank' rel="noreferrer">MAYRO IT</a>
                        </styled.FooterSubText>
                        <styled.FooterSubText>
                            Copyright © {new Date().getFullYear()}  MAYRO Comics. All Rights Reserved
                        </styled.FooterSubText>
                    </styled.FooterSubContainer>
                </styled.FooterContainer>
            </div>
        </styled.Footer>
    )
}

const FooterItem = ({ to, title, isLinkOtherPage }) => {
    const linkProps = isLinkOtherPage ? { href: to, as: 'a', target: "_blank", rel: "noopener noreferrer" } : { to };
    return (
        <styled.FooterNavItem>
            <styled.FooterNavLink {...linkProps}>
                {title}
            </styled.FooterNavLink>
        </styled.FooterNavItem>
    )
}

export default Footer