import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({children}) => {
    return (
        <>
            <Header />
            <React.Suspense >
                {children}
            </React.Suspense>
            <Footer />

        </>
    )
}

export default Layout