import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { Swiper } from "swiper/react";
/*import {Link} from "react-router-dom";*/

export const Cooperate = styled.section`
  margin-top: 9.3rem;
  margin-bottom: 7.5rem;
  margin-left: 9.375%;
  @media(max-width: 768px){
    margin-top: 3.5625rem;
    margin-left: 6.4%;
    margin-bottom: 2.875rem;
  }
`

export const CooperateContainer = styled.div`
  display: flex;
  @media(max-width: 1440px){
    align-items: center;
  }
  @media(max-width: 768px){
    flex-direction: column;
  }
`

export const CooperateTextContainer = styled.div`
  width: 36.015%;
  margin-right: 1.875rem;
  flex-shrink: 0;
  @media(max-width: 768px){
    width: auto;
    margin: 0 0 1.6875rem 0;
    padding-right: .9rem;
  }
`

export const CooperateTitle = styled.h2`
  margin-top: 0.62rem;
  margin-bottom: 0;

  color: var(${colors.primary});

  font-family: 'Teko', sans-serif;
  font-size: 3.125rem;
  line-height: 1;
  font-weight: 700;
  text-transform: capitalize;
  @media(max-width: 768px){
    font-size: 1.875rem;
  }
`

export const CooperateSubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  max-width: 15.262rem;
  @media(max-width: 768px){
    max-width: 9.2rem;
    margin-bottom: 1.5rem;
  }
`

export const CooperateSubTitleLine = styled.div`
  height: 0.25rem;
  flex-grow: 1;

  background-color: var(${colors.primary});
`

export const CooperateSubTitle = styled.span`
  margin-left: 0.812rem;

  color: var(${colors.primary});

  font-family: 'Teko', sans-serif;
  font-size: 3.125rem;
  line-height: 1;
  font-weight: 700;
  text-transform: capitalize;
  @media(max-width: 768px){
    font-size: 1.875rem;
  }
`

export const CooperateDesc = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  height:8rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.361rem;
  
  @media(max-width: 768px){
    font-size: .75rem;
    line-height: .875rem;
  }
`

export const CooperateLogoContainer = styled.div`
  display: flex;
  margin-bottom: 1.2rem;
  @media(max-width: 768px){
    margin-bottom: 1rem;
  }
`

export const CooperateLogo = styled.div`
  position: relative;
  width: ${props => props.logoWidth ? props.logoWidth : ''};
  margin-right: ${props => props.marginRight ? props.marginRight : ''};

  &::before {
    content: '';
    display: block;
    padding-top: ${props => props.logoHeight ? props.logoHeight : ''};
  }
`
export const CooperateSwiperContainer = styled.div`
  width: calc(100% - 36.015% - 1.875rem);
  flex-shrink: 0;
  @media(max-width: 768px){
    width: 100%;
  }
`

export const CooperateSwiper = styled(Swiper)`
padding-bottom: 3.068rem;

  .cooperate-swiper-nav {
    margin-right: 16.77018633540373%;
    
    img {
      transition: transform 0.4s ease, width 0.4s ease, height 0.4s ease;

      &:hover {
        transform: scale(1.2); /* Scale up the image on hover */
      }
    }
  }

  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .swiper-slide-active div::before {
    padding-top: 74%;
  }

  .swiper-slide:not(.swiper-slide-active)::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-color: var(${colors.black47});
    z-index: 1;
  }

  @media(max-width: 768px) {
    .cooperate-swiper-nav {
      margin-right: 1rem;

      img {
        &:hover {
          transform: none; /* Disable scaling on hover for mobile */
        }
      }
    }
  }
`;

export const CooperateSwiperImage = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 69.207%;
  }
`