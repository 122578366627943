import React from "react";

const Svg = () => {
    return (
        <svg width="0" height="0" className="hidden">
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6" id="arrow-down">
                <path d="M10 -9.53674e-07L4.29138e-07 -7.94466e-08L5 6" />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" id="swiper-arrow-left">
                <path d="M9.70091 0.612407L0.736065 8.39132C0.654944 8.46163 0.589886 8.54856 0.545299 8.64622C0.500711 8.74388 0.477635 8.84998 0.477635 8.95733C0.477635 9.06468 0.500711 9.17078 0.545299 9.26844C0.589886 9.3661 0.654944 9.45303 0.736065 9.52335L9.70091 17.2999C9.72793 17.3234 9.76114 17.3386 9.79657 17.3438C9.83201 17.349 9.86818 17.3438 9.90078 17.329C9.93338 17.3142 9.96104 17.2903 9.98045 17.2602C9.99987 17.2301 10.0102 17.1951 10.0103 17.1593L10.0103 15.2608C10.0103 15.153 9.96341 15.0499 9.88138 14.9773L3.9681 9.84678L21.8125 9.84678C21.9156 9.84678 22 9.76241 22 9.65928L22 8.25303C22 8.14991 21.9156 8.06553 21.8125 8.06553L3.9681 8.06553L9.88138 2.93506C9.96341 2.86475 10.0103 2.76163 10.0103 2.65147L10.0103 0.753032C10.0103 0.593658 9.82278 0.506939 9.70091 0.612407Z" />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" id="swiper-arrow-right">
                <path d="M12.2991 17.3876L21.2639 9.60868C21.3451 9.53837 21.4101 9.45144 21.4547 9.35378C21.4993 9.25612 21.5224 9.15002 21.5224 9.04267C21.5224 8.93532 21.4993 8.82922 21.4547 8.73156C21.4101 8.6339 21.3451 8.54697 21.2639 8.47665L12.2991 0.700091C12.2721 0.676592 12.2389 0.661355 12.2034 0.656196C12.168 0.651036 12.1318 0.656172 12.0992 0.67099C12.0666 0.685809 12.039 0.709686 12.0195 0.739777C12.0001 0.769868 11.9898 0.804906 11.9897 0.840716L11.9897 2.73915C11.9897 2.84697 12.0366 2.95009 12.1186 3.02275L18.0319 8.15322L0.1875 8.15322C0.0843751 8.15322 -3.31476e-07 8.23759 -3.35984e-07 8.34072L-3.97453e-07 9.74697C-4.01961e-07 9.85009 0.084375 9.93447 0.1875 9.93447L18.0319 9.93447L12.1186 15.0649C12.0366 15.1352 11.9897 15.2384 11.9897 15.3485L11.9897 17.247C11.9897 17.4063 12.1772 17.4931 12.2991 17.3876Z"/>
            </symbol>
        </svg>

    )
}

export default Svg