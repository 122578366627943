import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";

import Nav, { NavDropdownItem, NavItem } from "./Nav";
import MobileNav from "./NavMobile";
import logo from "../../assets/img/logo.png";

import * as styled from "./style";

const headerColorPerPage = {
  "/": {
    icon: "primary",
    links: "black",
  },
  "/comic-books": "black",
  "/short-comics": "black",
  "/gallery": "white",
  "/shop": "black",
  "/contact-us": "white",
  "/about-us": "white",
};

const Header = () => {
  const windowSizeX = useWindowSize();
  const location = useLocation();
  return (
    <styled.Header>
      <div className="page-container">
        <styled.HeaderContainer>
          <styled.Logo>
            <span className="img-container">
              <img src={logo} alt="logo" />
            </span>
          </styled.Logo>
          {windowSizeX >= 768 ? (
            <Nav
              linksColor={
                location.pathname === "/"
                  ? headerColorPerPage[location.pathname].links
                  : headerColorPerPage[location.pathname]
              }
            >
              <NavItem title="Comics" dropdown to="/">
                <NavDropdownItem to="/comic-books" title="Comic Books" />
                <NavDropdownItem to="/short-comics" title="Short Comics" />
              </NavItem>
              <NavItem title="Gallery" to="gallery" />
              <NavItem title="Shop" to="shop" />
              <NavItem title="About us" to="about-us" />
              <NavItem title="Contact us" to="contact-us" />
            </Nav>
          ) : (
            <MobileNav
              iconColor={
                location.pathname === "/"
                  ? headerColorPerPage[location.pathname].icon
                  : headerColorPerPage[location.pathname]
              }
              location={location}
            />
          )}
        </styled.HeaderContainer>
      </div>
    </styled.Header>
  );
};

export default Header;
