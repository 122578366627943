import React from "react";

import * as styled from "./style";
import prevArrow from "../../../assets/img/comicsSwiper/leftButton.png";
import nextArrow from "../../../assets/img/comicsSwiper/rightButton.png";


const SwiperNavigation = ({ className, handleClickPrev, handleClickNext }) => {

    return (
        <styled.SwiperNavigation className={className}>
            <styled.SwiperNavigationIcon src={prevArrow} className="prev" onClick={handleClickPrev} />
            <styled.SwiperNavigationIcon src={nextArrow} className="next" onClick={handleClickNext} />
        </styled.SwiperNavigation>
    )
}

export default SwiperNavigation;