import styled from "styled-components";
import { colors } from "../../../utils/theme";
import rightButton from "../../../assets/img/shortComicsSwiper/rightButton.png";
import leftButton from "../../../assets/img/shortComicsSwiper/leftButton.png";
import { Swiper } from "swiper/react";

export const ShortComics = styled.section`
  position: relative;
  margin-top: 2.15rem;
  margin-bottom: 2.15rem;
`

export const ShortComicsBack = styled.div`
  position: absolute;
  top: 0;
  right: 3.25rem;
  bottom: 0;
  left: 3.25rem;
  background-color: var(${colors.primary});
  z-index: 1;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 38%;
    border-right: 2.125rem solid transparent;
    border-bottom: 2.125rem solid var(${colors.primary});
    bottom: 100%;
  }
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    width: 38%;
    border-top: 2.125rem solid var(${colors.primary});
    border-left: 2.125rem solid transparent;
  }
  @media(max-width: 768px){
    width: 100%;
    left: unset;
    right: unset;
    &::before {
  
      width: 34%;
      border-right: .7rem solid transparent;
      border-bottom: 1rem solid var(${colors.primary});
    }

    &::after {
      width: 34%;
      border-top: 1rem solid var(${colors.primary});
      border-left: .7rem solid transparent;
    }
  }
`
export const ShortComicsContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  z-index: 2;
  @media(max-width: 768px){
    flex-direction: column;
  }
`

export const ShortComicsSwiperContainer = styled.div`
  height: 100%;
  width: 50%;
  flex-shrink: 0;
  @media(max-width: 768px){
    width: calc(100% - 2.5rem);
    padding-left: 2.5rem;
  }
  @media(max-width: 375px){
    width: calc(100% - 6.4%);
    padding-left: 6.4%;
  }
`

export const ShortComicsSwiper = styled(Swiper)`
  height: 100%;
  padding-top: 2.437rem;
  padding-bottom: 4.25rem;
  
  .swiper-wrapper {
    padding-left: 50%;
  }
  
  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .swiper-slide-active div::before {
    padding-top: 151.734%;
  }
  
  .swiper-slide-prev::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-color: var(${colors.black47});
    z-index:1;
  }
  
  .swiper-button-next {
    position: absolute;
    bottom: 1.6rem;
    left: 11.3rem;
    background-image: url(${rightButton});
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.4rem;
    height: 1.345rem;
    cursor: pointer;
    transition: transform 0.4s ease, width 0.4s ease, height 0.4s ease;

    &:hover {
      transform: scale(1.2); /* Scale up on hover */
      width: 1.68rem; /* Increase width on hover */
      height: 1.614rem; /* Increase height on hover */
    }
  }
  
  .swiper-button-prev {
    position: absolute;
    bottom: 1.6rem;
    left: 8.5rem;
    background-image: url(${leftButton});
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.4rem;
    height: 1.345rem;
    cursor: pointer;
    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;

    &:hover {
      transform: scale(1.2); /* Scale up on hover */
      width: 1.68rem; /* Increase width on hover */
      height: 1.614rem; /* Increase height on hover */
    }
  }
  
  @media(max-width: 768px){
    padding-top: 0;
    padding-bottom: 3rem;
    .swiper-wrapper {
      padding-left: 0;
    }
    .swiper-slide-next::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background-color: var(${colors.black47});
      z-index:1;
    }
    .swiper-slide-prev::before {
      content: unset;
    }
    .swiper-button-next {
      left: unset;
      right: 1.1875rem;
      bottom: 0;

      &:hover {
        transform: scale(1.2); /* Scale up on hover */
        width: 1.68rem; /* Increase width on hover */
        height: 1.614rem; /* Increase height on hover */
      }
    }

    .swiper-button-prev {
      left: unset;
      right: 3.875rem;
      bottom: 0;

      &:hover {
        transform: scale(1.2); /* Scale up on hover */
        width: 1.68rem; /* Increase width on hover */
        height: 1.614rem; /* Increase height on hover */
      }
    }
    .swiper-slide-active div::before {
      padding-top: 141.734%;
    }
  }
`;

export const ShortComicsSwiperImage = styled.div`
  position: relative;
  width: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
  &::before {
    content: '';
    display: block;
    padding-top: 141.734%;
  }
`

export const ShortComicsTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 3.1rem;
  @media(max-width: 768px){
    justify-content: center;
    width: 100%;
    order: -1;
    padding-right: 1.9375rem;
  }
`

export const ShortComicsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 17.2%;
  margin-right: 14%;
  margin-bottom: 4.6rem;
  @media(max-width: 768px){
    align-items: center;
    max-width: 30rem;
    margin-bottom: 1.5rem;
    margin-right: 0;
    margin-left: 2.5rem;
  }
  @media(max-width: 375px){
    align-items: flex-start;
    margin-left: 6.4%;
  }
`

export const ShortComicsTitle = styled.h2`
  margin-bottom: 0;
  color: var(${colors.white});
  font-family: 'Teko', sans-serif;
  font-size: 3.125rem;
  line-height: 4.478rem;
  font-weight: 700;
  letter-spacing: 0.312rem;
  text-transform: capitalize;
  position:relative;
  &::before{
    content: 'comics';
    position: absolute;
    bottom: 0;
    left: 2.4rem;
    color: rgba(200, 49, 52, 1);
    
    font-size: 7rem;
    line-height: 6.125rem;
    font-weight: 700;
    text-transform: none;
    z-index: -1;
  }
  @media(max-width: 768px){
    font-size: 1.875rem;
    line-height: 2.6875rem;
    &:before{
      content:''
    }
  }
`

export const ShortComicsDesc = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(${colors.white});
  
  font-family: 'Open Sans', sans-serif;
  font-size: 1.005rem;
  line-height: 1.361rem;
  @media(max-width: 768px){
    font-size: .75rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;
  }
`
