import {useState} from "react";
import * as styled from "./style"
import InputSimple from "../InputSimple";
import PrimaryBtn from "../PrimaryBtn";


const SubscribeForm = ({successfulHandler})=>{
   const [email, setEmail]  = useState('')
   const onChangeHandler = (e)=>{
       setEmail(e.target.value)
   }
   const submitHandler = (evt)=>{
       evt.preventDefault()
       if(successfulHandler){
           successfulHandler(true)
       }
       setEmail('')
   }

    return (
        <styled.SubscribeFormBox className='subscribe' onSubmit={submitHandler}>
            <InputSimple
                className='subscribe-input'
                value={email}
                onChange={onChangeHandler}
            />
            <PrimaryBtn
                type='submit'
                className='subscribe-btn'
            >
                Subscribe
            </PrimaryBtn>
        </styled.SubscribeFormBox>
    )
}

export default SubscribeForm;