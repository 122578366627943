import styled from "styled-components";
import {colors} from "../../../utils/theme";
import {NavLink} from "react-router-dom";

export const NavMobile = styled.div`
`
export const NavIcon = styled.div`
    svg path{
      stroke: ${props=>props.iconColor ? `var(${colors[props.iconColor]})` : `var(${colors.black})`};
    }
`
export const NavMobileWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: scroll;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;
  
    background-color: var(${colors.primary});
`
export const NavHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
export const NavMobileList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
`
export const NavItem = styled.li`
    margin-bottom: .9375rem;
`
export const NavItemLink = styled(NavLink)`
    font-family: 'Teko',  sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.8125rem;
    text-decoration: none;
    color: var(${colors.white});
`
export const NavSocials = styled.div`
    display: flex;
    margin-top: 4rem; 
`
export const SocialLink = styled.a`
  margin-right: 1.1875rem;
  &:last-child{
    margin-right: 0;
  }
`
export const CloseIcon = styled.img`
    position: absolute;
    right: 1.325rem;
    top: 1.325rem;
`


export const Logo = styled.img`
    position: absolute;
    top: 1.325rem;
    left:2rem;
    width: 6rem
`