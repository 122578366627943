import React from "react";
import ComicsSwiper from "./Comics";
import ShortComics from "./ShortComics";
import Parallax from "./Parallax";
import InfoList from "./InfoList";
import Cooperate from "./Cooperate";

const HomePage = () => {
    return (
        <div>
            <ComicsSwiper />
            <ShortComics />
            <Cooperate />

            <Parallax />
            <InfoList />
        </div>
    )
}

export default HomePage