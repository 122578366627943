import {useState, useEffect} from "react";
//refactoring, return isMobile boolean
const useWindowSize = ()=>{
    const [windowSizeX, setWindowSizeX] = useState( window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowSizeX( window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSizeX;
}

export default useWindowSize;