import React from "react";
import * as styled from './style'

const InfoList = () => {
    return (
        <styled.InfoList>
            <div className='page-container-mini'>
                <styled.InfoListContainer>
                    <styled.InfoListTitle>
                        Why Read Comic Books ?
                    </styled.InfoListTitle>
                    <styled.InfoListList>
                        <styled.InfoListItem>
                            Theye Are More <br />
                            than Just Superhero Stories
                        </styled.InfoListItem>
                        <styled.InfoListItem>
                            They Help Us <br />
                            To Think Differently
                        </styled.InfoListItem>
                        <styled.InfoListItem>
                            They Help People <br />
                            Learn to Love Reading
                        </styled.InfoListItem>
                    </styled.InfoListList>
                </styled.InfoListContainer>
            </div>
        </styled.InfoList>
    )
}

export default InfoList;