import React, {useEffect} from 'react';
import {GlobalStyle} from '../utils/global';
import {themeColorsArray} from "../utils/theme";
import Svg from "../components/Svg";

Number.prototype.toTruncFixed = function () {
    return (parseInt(this * 100) / 100).toFixed(2);
};

const setVhSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

function InitialConfigProvider({children}) {
    useEffect(() => {
        setVhSize();
        window.addEventListener('resize', setVhSize);
        return () => {
            window.removeEventListener('resize', setVhSize);
        };
    }, []);
    return (
        <>
            <GlobalStyle colors={themeColorsArray[0].colors}/>
            <Svg/>
            {children}
        </>
    );
}

export default InitialConfigProvider;
