import React from "react";
import { useNavigate } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import PrimaryBtn from "../../../components/Global/PrimaryBtn";
import * as styled from './style'
import GameOfThronesImg1 from '../../ShortComics/images/gameOfThrones1.jpg';
import GameOfThronesImg2 from '../../ShortComics/images/gameOfThrones2.jpg';
import GameOfThronesImg3 from '../../ShortComics/images/gameOfThrones3.jpg';


const ShortComics = () => {
    const navigate = useNavigate();
    const handRedirect = () => {
        navigate('/short-comics')
    }
    return (
        <styled.ShortComics>
            <styled.ShortComicsBack />
            <styled.ShortComicsContainer>
                <styled.ShortComicsSwiperContainer>
                    <styled.ShortComicsSwiper
                        navigation={true}
                        loop={true}
                        modules={[Navigation]}

                        breakpoints={
                            {

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 16
                                },
                                100: {
                                    slidesPerView: 1.74,
                                    spaceBetween: 16
                                }
                            }
                        }
                    >
                        <SwiperSlide>
                            <styled.ShortComicsSwiperImage>
                                <div className='img-container'>
                                    <img src={GameOfThronesImg1} alt="slider1" />
                                </div>
                            </styled.ShortComicsSwiperImage>
                        </SwiperSlide>
                        <SwiperSlide>
                            <styled.ShortComicsSwiperImage>
                                <div className='img-container'>
                                    <img src={GameOfThronesImg2} alt="slider1" />
                                </div>
                            </styled.ShortComicsSwiperImage>
                        </SwiperSlide>
                        <SwiperSlide>
                            <styled.ShortComicsSwiperImage>
                                <div className='img-container'>
                                    <img src={GameOfThronesImg3} alt="slider1" />
                                </div>
                            </styled.ShortComicsSwiperImage>
                        </SwiperSlide>
                    </styled.ShortComicsSwiper>
                </styled.ShortComicsSwiperContainer>

                <styled.ShortComicsTextContainer>
                    <styled.ShortComicsTextWrapper>
                        <styled.ShortComicsTitle>
                            Short Comics
                        </styled.ShortComicsTitle>
                        <styled.ShortComicsDesc>
                        Our short comics are small but interesting stories about different situations of everyday life. We've presented "Jazzve", "Game of Thrones", and "Under one  Roof" comics, whose stories will amaze you. We assure you that there is no such episode from life that would not be presented in our short comics. Don't believe it?
                        </styled.ShortComicsDesc>
                        <PrimaryBtn
                            backgroundColor='white'
                            onClickBtn={handRedirect}
                        >
                            See More
                        </PrimaryBtn>
                    </styled.ShortComicsTextWrapper>
                </styled.ShortComicsTextContainer>
            </styled.ShortComicsContainer>
        </styled.ShortComics>
    )
}

export default ShortComics