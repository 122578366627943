import styled from "styled-components";

export const SwiperNavigation = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 9.375%;
    cursor: pointer;
`;

export const SwiperNavigationIcon = styled.img`
    margin-top: 2.125rem;
    transition: transform 0.3s ease, width 0.4s ease, height 0.4s ease; 

    &:first-child {
        margin-right: 1.375rem;
    }

    &:hover {
        transform: scale(1.2); /* Scale up the icon on hover */
    }
`;
