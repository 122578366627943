import styled from "styled-components";
import {colors} from "../../../utils/theme";

export const InfoList = styled.section`
  padding-top: 7.5rem;
  padding-bottom: 7rem;
  @media(max-width: 768px){
    padding: 4.4375rem 0;
  }
`

export const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InfoListTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 3.25rem;
  
  color: var(${colors.primary});
  
  font-family: 'Teko', sans-serif;
  font-size: 3.125rem;
  line-height: 3.571rem;
  font-weight: 700;
  text-transform: capitalize;
  @media(max-width: 768px){
    font-size: 1.875rem;
    line-height: 115%;
    margin-bottom: 3.375rem;
  }
`

export const InfoListList = styled.ol`
  display: flex;
  justify-content: space-around;
  counter-reset: info;
  list-style-type: none;
  width: 100%;
  @media(max-width: 768px){
    max-width: 12.5rem;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
`

export const InfoListItem = styled.li`
  position: relative;
  padding-left: 1.062rem;
  flex-grow: 1;
  
  color: var(${colors.black});

  font-family: 'Teko', sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  text-transform: capitalize;
  
  &::before {
    content: counter(info) '';
    counter-increment: info;
    display: inline-block;
    position: absolute;
    transform: translateY(-50%);
    top: 38%;
    left: -0.3rem;
    opacity: 30%;
    
    color: var(${colors.primary});

    font-family: 'Teko', sans-serif;
    font-size: 6.25rem;
    line-height: 7.5rem;
    font-weight: 700;
    
    text-align: right;
  }
  @media(max-width: 768px){
    margin-bottom: 3.375rem;
    font-size: 1.25rem;
    line-height: 120%;
    &:last-child{
      margin-bottom: 0;
    }
  }
`