import styled from "styled-components";
import {Link} from "react-router-dom";

export const Header = styled.header`
  position: relative;
  z-index: 100;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  
  background-color: transparent;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled(Link).attrs(props => ({
    to: '/',
}))`
  position: relative;
  display: block;
  width: 9.875rem;

  &::before {
    content: '';
    display: block;
    padding-top: 28.481%;
  }
  @media(max-width: 768px){
    width: 5.935rem;
    height: 1.6875rem;
  }
`