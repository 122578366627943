import styled from "styled-components";
import {colors} from "../../utils/theme";
import {NavLink} from "react-router-dom";
import background from '../../assets/img/footerBack.jpg'

export const Footer = styled.footer`
  position: relative;
  margin-top: auto;
  padding-top: 3.125rem;
  padding-bottom: 1.812rem;
  z-index: 0;
  
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const FooterOpacity = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  
  background-color: var(${colors.black83});
`

export const FooterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
`

export const FooterNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.962rem;
  @media(max-width: 768px){
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3.75rem;
  }
`
export const FooterTitle = styled.h2`
  margin-bottom: 0.52rem;

  color: var(${colors.white});

  font-family: 'Teko', sans-serif;
  font-size: 1.25rem;
  line-height: 1.791rem;
  font-weight: 700;
  @media(max-width: 768px){
    margin: 0 0 1rem 0;
  }
`

export const FooterNavElement = styled.div`
  display: flex;
  flex-direction: column;
  @media(max-width: 768px){
    flex: 0 0 8.3125rem;
    margin-bottom: 2.75rem;
    &:last-child{
      width: 100%;
      max-width: 20.75rem;
      margin-bottom: 0;
    }
    &:last-child ${FooterTitle}{
      margin: 0;
    }
    align-items: center;
  }
 
`
export const FooterNavList = styled.ul`
  margin: 0;
  padding: 0;

  list-style-type: none;
`

export const FooterNavItem = styled.li`
  @media(max-width: 768px){
    margin-bottom: .5rem;
  }
`

export const FooterNavLink = styled(NavLink)`
  color: var(${colors.white});

  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: capitalize;
  text-decoration: none;
`

export const FooterNavText = styled.span`
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : ''};

  color: var(${colors.white});

  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
`

export const FooterNavForm = styled.div`
  display: flex;
  height: 42px;
  margin-bottom: 0.937rem;
  .subscribe-input{
    flex-shrink: 0;
  }
  .subscribe-btn{
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.343rem;
  }
  @media(max-width: 768px){
    width: 100%;
    .subscribe-input{
      width: inherit;
      flex: 0 1 15.3125rem;
    }
    .subscribe-btn{
      width: 5.4375rem;
    }
  }
`
export const FooterSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media(max-width: 768px){
    align-items: center;
  }
`

export const FooterSubText = styled.span`
  color: var(${colors.white});
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.851rem;

  a {
    color: inherit; 
    text-decoration: none; 

  &:not(:last-child) {
    margin-bottom: 0.062rem;
  }
`;