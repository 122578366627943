import styled from "styled-components";
import {colors} from "../../../utils/theme";

export const Btn = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;
  
    width: ${(props)=> props.miniBtn ? '133px' : '170px'};
    height: ${(props)=> props.miniBtn ? '42px' : '52px'};
    max-width: 100%;
    max-height: 100%;
  
    box-sizing: border-box;
  
    font-family: 'Teko', sans-serif;
    font-size: 1.125rem;
    line-height: inherit;
    font-weight: 700;
  
    background-color: ${props=>props.backgroundColor ? `var(${ colors[props.backgroundColor] })`: `var(${ colors.primary })`};
    color: ${props=>props.backgroundColor ? `var(${ colors.primary })`: `var(${ colors.white })`};
  
    outline: none;
    border: none;
    cursor:pointer;
`