import {createGlobalStyle} from "styled-components";
import {colors} from "./theme";

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
 
  body {
    ${colors.white}: ${props => props.colors.white};
    ${colors.white2}: ${props => props.colors.white2};
    ${colors.black}: ${props => props.colors.black};
    ${colors.black47}: ${props => props.colors.black47};
    ${colors.black83}: ${props => props.colors.black83};
    ${colors.primary}: ${props => props.colors.primary};
    ${colors.primarySecond}: ${props => props.colors.primarySecond};
    ${colors.primaryThird}: ${props => props.colors.primaryThird};
    ${colors.primaryForth}: ${props => props.colors.primaryForth};
    margin: 0;
    padding: 0;
  }

  .page-container {
    width: 81.25%;
    margin: 0 auto;
  }

  .page-container-mini { 
    width: 67.361%;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
   .page-container-mini{
     width: 81.25%;
    }
  }
  @media (max-width: 375px) {
    .page-container, .page-container-mini{
      width: 91.466%;
    }
  }
  .img-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  .hidden {
    display: none;
  }
  
  #mayro {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh) * 100 - 1px);
  }
  a{
    color:none
  }
`