import { SwiperSlide } from "swiper/react";
import { Navigation, Parallax } from "swiper";
import 'swiper/css';
import SwiperNavigation from "../../../components/Global/SwiperNavigation";

import PrimaryBtn from "../../../components/Global/PrimaryBtn";
import * as styled from './style'
import { useNavigate } from "react-router-dom";
import { comicsBookDescriptionUtil } from "./comicsBookDescriptionUtil";


const ComicsSwiper = () => {
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/comic-books')
    };
    return (
        <styled.Comics>
            <div className='page-container'>
                <styled.ComicsSwiper
                    modules={[Navigation, Parallax]}
                    speed={1000}
                    parallax={true}
                    loop={true}
                    navigation={
                        {
                            nextEl: '.next',
                            prevEl: '.prev'
                        }
                    }
                    spaceBetween={50}
                    slidesPerView={1}

                >
                    {comicsBookDescriptionUtil.map((comics, i) =>
                        <SwiperSlide key={i}
                        >
                            <styled.ComicsSlideWrapper
                            >
                                <styled.ComicsSwiperLeft>
                                    <styled.ComicsTitleContainer>
                                        <styled.ComicsTitle>
                                            {comics?.comicsTitle}
                                        </styled.ComicsTitle>
                                        <styled.ComicsTitleOrder>
                                            {comics?.comicsNumber}
                                        </styled.ComicsTitleOrder>
                                        <styled.ComicsTextContainer>
                                            <styled.ComicsTextLine />
                                            <styled.ComicsText>Comics</styled.ComicsText>
                                        </styled.ComicsTextContainer>
                                    </styled.ComicsTitleContainer>
                                    <styled.ComicsDesc>
                                        {comics.comicsDescription}
                                    </styled.ComicsDesc>
                                    <styled.ComicsDesc>
                                    </styled.ComicsDesc>
                                    <PrimaryBtn
                                        onClickBtn={handleRedirect}
                                    >
                                        Buy Now
                                    </PrimaryBtn>
                                </styled.ComicsSwiperLeft>
                                <styled.ComicsSwiperRight>
                                    <styled.ComicsSwiperImg>
                                        <div className='img-container'>
                                            <img src={comics.comicsImg} alt='slider1' />
                                        </div>
                                    </styled.ComicsSwiperImg>
                                </styled.ComicsSwiperRight>
                            </styled.ComicsSlideWrapper>
                        </SwiperSlide>
                    )}
                    {/* <SwiperSlide>
                        <styled.ComicsSlideWrapper>
                            <styled.ComicsSwiperLeft>
                                <styled.ComicsTitleContainer>
                                    <styled.ComicsTitle>
                                        “Velvet Revolution”
                                    </styled.ComicsTitle>
                                    <styled.ComicsTitleOrder>
                                        02
                                    </styled.ComicsTitleOrder>
                                    <styled.ComicsTextContainer>
                                        <styled.ComicsTextLine />
                                        <styled.ComicsText>Comics</styled.ComicsText>
                                    </styled.ComicsTextContainer>
                                </styled.ComicsTitleContainer>
                                <styled.ComicsDesc>
                                    The "Velvet111 Revolution" comic book is about the events that took place in Armenia a year ago, and was later characterized as "revolution" by people in Armenia.
                                </styled.ComicsDesc>
                                <PrimaryBtn>
                                    Buy Now
                                </PrimaryBtn>
                            </styled.ComicsSwiperLeft>
                            <styled.ComicsSwiperRight>
                                <styled.ComicsSwiperImg>
                                    <div className='img-container'>
                                        <img src={sliderImg} alt='slider1' />
                                    </div>
                                </styled.ComicsSwiperImg>
                            </styled.ComicsSwiperRight>
                        </styled.ComicsSlideWrapper>
                    </SwiperSlide>
                    <SwiperSlide>
                        <styled.ComicsSlideWrapper>
                            <styled.ComicsSwiperLeft>
                                <styled.ComicsTitleContainer>
                                    <styled.ComicsTitle>
                                        “Velvet Revolution”
                                    </styled.ComicsTitle>
                                    <styled.ComicsTitleOrder>
                                        01
                                    </styled.ComicsTitleOrder>
                                    <styled.ComicsTextContainer>
                                        <styled.ComicsTextLine />
                                        <styled.ComicsText>Comics</styled.ComicsText>
                                    </styled.ComicsTextContainer>
                                </styled.ComicsTitleContainer>
                                <styled.ComicsDesc>
                                    The "Velvet Revolution" comic book is about the events that took place in Armenia a year ago, and was later characterized as "revolution" by people in Armenia.
                                </styled.ComicsDesc>
                                <PrimaryBtn>
                                    Buy Now
                                </PrimaryBtn>
                            </styled.ComicsSwiperLeft>
                            <styled.ComicsSwiperRight>
                                <styled.ComicsSwiperImg>
                                    <div className='img-container'>
                                        <img src={sliderImg} alt='slider1' />
                                    </div>
                                </styled.ComicsSwiperImg>
                            </styled.ComicsSwiperRight>
                        </styled.ComicsSlideWrapper>
                    </SwiperSlide> */}
                    <SwiperNavigation className='h-comics-swiper-nav' />

                </styled.ComicsSwiper>
            </div>
        </styled.Comics>

    )
}

export default ComicsSwiper;