import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {colors} from "../../../utils/theme";

export const Nav = styled.nav`
  a, button>span {
    color: var(${(props)=> props.linkColor ? colors[props.linkColor]: colors.black});
  }
  button>span svg{
    fill: var(${(props)=> props.linkColor ? colors[props.linkColor]: colors.black});
  }
`;

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const NavItem = styled.li`
  position: relative;

  ${props => props.dropdown && css`
    display: flex;
  `}
  &:not(:last-child) {
    margin-right: 3.125rem;
  }
`

export const NavItemIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 0.625rem;
  margin-left: 0.187rem;

  svg {
    fill: ${props => props.active ? `var(${colors.primary})` : `var(${colors.black})`};
  }

  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
`

export const NavItemLink = styled(NavLink)`
  position: relative;
  
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.106rem;
  text-transform: capitalize;
  text-decoration: none;
  &.active{
    &:after{
      content: '';
      position: absolute;
      top: calc(100% + 0.562rem);
      right: 0;
      width: 2.375rem;
      height: 2px;
      background-color: var(${colors.primary});
    }
  }
`

export const NavItemButton = styled.button.attrs({
    type: 'button'
})`
  display: flex;
  align-items: center;
  cursor: pointer;

  border: none;
  background-color: transparent;
`

export const NavItemText = styled.span`
  color: ${props => props.active ? `var(${colors.primary})` : `var(${colors.black})`};

  font-family: 'Open Sans', sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.106rem;
  text-transform: capitalize;
`

export const NavDropdown = styled.div`
  position: absolute;
  top: calc(100% + 0.562rem);
  right: 0;
  padding: 0.875rem 0.718rem;
  border-top: 0.187rem solid var(${colors.primary});
  background-color: #F5F5F5;
  z-index: 20;
  a{
    color: #373737;
    &.active{
      color: var(${colors.primary});
    }
  }
  a:hover{
    color: var(${colors.primary});
  }
`

export const NavDropdownList = styled.ul`
  list-style-type: none;
  padding: 0;
`

export const NavDropdownItem = styled.li`

`

export const NavDropdownLink = styled(NavLink)`
  display: inline-block;
  width: max-content;

  color: var(${colors.primary});

  font-family: 'Montserrat', sans-serif;
  font-size: 0.812rem;
  line-height: 0.990rem;
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`