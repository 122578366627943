import styled from "styled-components";
import {colors} from "../../../utils/theme";

export const InpSimple = styled.input.attrs(props => ({
    type: 'email',
    placeholder: props.placeholder,
}))`
  /*flex-shrink: 0;*/
  box-sizing: border-box;
  width: 100%;
  padding: 0.812rem;
  border: none;
  outline: none;

  color: var(${colors.black});
  background-color: var(${colors.primaryThird});

  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;

  &::placeholder {
    color: var(${colors.primarySecond});
  }
`