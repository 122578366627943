import {useState, useEffect, useRef} from "react";
import * as styled from "./style";
//icons
import closeIcon from "../../../assets/img/common-images/close.svg";
import facebookIcon from "../../../assets/img/common-images/facebook-icon.svg";
import instagramIcon from "../../../assets/img/common-images/insta-icon.svg";
import linkedinIcon from "../../../assets/img/common-images/linkedin-icon.svg";
import logo from '../../../assets/img/logo.png'
import { useNavigate } from "react-router-dom";

//static data
const linksList = [
    {name: 'About Us', to:'/about-us'},
    {name: 'Shop', to:'/shop'},
    {name: 'Comic Books', to:'/comic-books'},
    {name: 'Short Comics', to:'/short-comics'},
    {name: 'Gallery/Illustrations', to:'/gallery'},
]


const MobileNavBody = ({children, visibleToggle, location})=>{
    const firstRender = useRef(true)
const navigate = useNavigate()
    useEffect(()=>{
        const [body]= document.getElementsByTagName("body")
        body.style.overflowY = 'hidden'
        return ()=> body.style.overflowY = 'scroll'
    })
    useEffect(()=>{
        if(firstRender.current){
            firstRender.current = false
            return;
        }
        visibleToggle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])
   const handleNavigate =()=>{
    navigate('/')
   }
    return (
        <styled.NavMobileWrapper>
            <styled.Logo src={logo} alt='logo'  onClick={handleNavigate}/>
           
            <styled.CloseIcon onClick={visibleToggle} src={closeIcon} alt='close'/>
            <styled.NavMobileList>
                {children}
            </styled.NavMobileList>
            <styled.NavSocials>
                <styled.SocialLink href='https://www.facebook.com/mayro.comics' target='_blank' rel='noreferrer'>
                    <img src={facebookIcon} alt='facebook-icon' />
                </styled.SocialLink>
                <styled.SocialLink href='https://www.instagram.com/mayro_comics/' target='_blank' rel='noreferrer'>
                    <img src={instagramIcon} alt='instagram-icon'/>
                </styled.SocialLink>
                <styled.SocialLink href='https://www.linkedin.com/company/mayrocomics/' target='_blank' rel='noreferrer'>
                    <img src={linkedinIcon} alt='linkedin-icon' />
                </styled.SocialLink>
            </styled.NavSocials>
        </styled.NavMobileWrapper>
    )
}
const NavItem = ({linkName, to})=>{
    return (
        <styled.NavItem>
            <styled.NavItemLink to={to}>
                {linkName}
            </styled.NavItemLink>
        </styled.NavItem>
    )
}



const MobileNav = ({iconColor, location})=>{
    const [navVisible, setNavVisible] = useState(false)

    const visibleToggle = ()=>{
        setNavVisible((prevState=>!prevState))
    }
    return (
        <styled.NavMobile>
            <styled.NavIcon onClick={visibleToggle} iconColor={iconColor}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M2 12H22M2 19H22H2ZM2 5H22H2Z" stroke="white" strokeWidth="2"/>
                </svg>
            </styled.NavIcon>
            {
                navVisible &&  (
                    <MobileNavBody visibleToggle={visibleToggle} location={location}>
                        {
                            linksList.map((link)=>{
                                return <NavItem key={link.name} to={link.to} linkName={link.name}/>
                            })
                        }
                    </MobileNavBody>
                )
            }
        </styled.NavMobile>
    )
}

export default MobileNav;