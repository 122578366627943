import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import InitialConfigProvider from "../providers/InitialConfigProviders";

import HomePage from "../pages/Home";
import Layout from "../layout";

const AboutUs = React.lazy(() => import("../pages/AboutUs"));
const Shop = React.lazy(() => import("../pages/Shop"));
const Gallery = React.lazy(() => import("../pages/Gallery/index"));
const ContactUs = React.lazy(() => import("../pages/ContactUs/index"));
const ComicBooks = React.lazy(() => import("../pages/ComicBooks/index"));
const ShortComics = React.lazy(() => import("../pages/ShortComics/index"));
const SelectedProduct = React.lazy(() => import("../pages/SelectedProduct"))

export default function App() {
  return (
    <InitialConfigProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/comic-books" element={<ComicBooks />} />
            <Route path="/short-comics" element={<ShortComics />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:id" element={<SelectedProduct />} />
          </Routes>
        </Layout>
      </Router>
    </InitialConfigProvider>
  );
}
