import slideVelvetRevolution from '../Comics/images/slideVelvetRevolution.png';
import slideTork from '../Comics/images/slideTork.png'


export const comicsBookDescriptionUtil = [
    {
        comicsNumber: "01",
        comicsTitle: "“Velvet Revolution”",
        comicsDescription: "The “Velvet Revolution” comic book is about the event that took place in Armenia a year ago, and was later characterized as“revolution” by people in Armenia.This is not a story about politicians,instead its a story about the victory of the people and peace.",
        comicsImg: slideVelvetRevolution,
    },
    {
        comicsNumber: "02",
        comicsTitle: '“Tork Angegh“',
        comicsDescription: 'The “Tork Angegh“  comic book is about Armenian heritage and ancient mythology. Tork Angegh was an ancient Armenian masculine deity of strength, courage, of manufacturing and the arts. Tork Angegh was a shepherd, but not an ordinary one, he was heroic.',
        comicsImg: slideTork,
    },
]